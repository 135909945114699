import React, { createContext, useState, useEffect } from 'react';
import { createClient } from 'contentful';
import { useAuth, useUser } from '@clerk/clerk-react';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, child, get, set } from 'firebase/database';

const client = createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
});

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
};

const app = initializeApp(firebaseConfig);

const defaultValues = {
  school: {
    fields: {
      logo: '',
      name: '',
      information: '',
      step1Progress: '',
      step2Progress: '',
    },
  },

  isOverlayActive: false,
  setOverlayActive: () => {},

  videoUrl: '',
  setVideoUrl: () => {},

  progress: {},

  getProgress: () => {},
  completeStepProgress: () => {},
  completeLesson: () => {},

  userId: '',
  login: () => {},
  logout: () => {},
};

export const SiteContext = createContext(defaultValues);

export const SiteProvider = ({ children }) => {
  const [isOverlayActive, setOverlayActive] = useState(
    defaultValues.isOverlayActive
  );

  const [videoUrl, setVideoUrl] = useState(defaultValues.videoUrl);

  const { getToken, isSignedIn } = useAuth();
  const { user } = useUser();
  const auth = getAuth();

  const [school, setSchool] = useState(defaultValues.school);
  const [progress, setProgress] = useState(defaultValues.progress);
  const [userId, setUserId] = useState(defaultValues.userId);

  const isBrowser = typeof window !== 'undefined';
  const localStorageSchoolKey = `${process.env.GATSBY_SITE_NAME}:school-id`;

  useEffect(() => {
    initialiseSchool();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (isSignedIn) {
        const schoolId = isBrowser
          ? localStorage.getItem(localStorageSchoolKey)
          : null;

        const firebaseToken = await getToken({
          template: 'integration_firebase',
        });
        const userCredentials = await signInWithCustomToken(
          auth,
          firebaseToken
        );

        const userID = user.externalId
          ? user.externalId
          : userCredentials.user.uid;

        // if we don't have a school ID then check new firebase setup
        !schoolId && (await getSchool(userID));

        // if the user has an external ID check for this first in Firebase else use the original ID
        setUserId(userID);
        await getProgress(
          user.externalId ? user.externalId : userCredentials.user.uid
        );
      }
    }
    fetchData();
  }, [isSignedIn, auth]);

  const firebaseUserSetup = async user => {
    try {
      // Setup a new user with Contentful data
      let curriculumData = {};

      // Get Themes
      const themes = await client
        .getEntries({
          content_type: 'theme',
        })
        .catch(console.error);

      // Build up data
      themes.items.map(theme => {
        let questions = {};
        theme.fields.questions.map(question => {
          let lessons = {};
          question.fields.lessons.map(lesson => {
            return (lessons = {
              ...lessons,
              [lesson.sys.id]: {
                id: lesson.sys.id,
                complete: false,
              },
            });
          });
          return (questions = {
            ...questions,
            [question.sys.id]: {
              id: question.sys.id,
              lessons: lessons,
            },
          });
        });
        return (curriculumData = {
          ...curriculumData,
          [theme.sys.id]: {
            id: theme.sys.id,
            title: theme.fields.title,
            questions: questions,
          },
          step1Progress: [false, false, false, false],
          step2Progress: [false, false, false, false, false],
        });
      });

      // Add user to Firebase with data
      const db = getDatabase();
      await set(ref(db, 'users/' + user), curriculumData);
      setProgress(curriculumData);
    } catch (e) {
      console.error(e);
    }
  };

  const getSchool = async id => {
    // Get and set school data from Contentful
    try {
      await client
        .getEntry(id, { include: 3 })
        .then(response => {
          if (response) {
            setSchool(response);
          }
        })
        .catch(async () => {
          // try and check firebase
          try {
            // Check for existing user in firebase and get progress - else setup a new user
            const dbRef = ref(getDatabase(app));

            await get(child(dbRef, `schools/${id}`))
              .then(async snapshot => {
                if (snapshot.exists()) {
                  setSchool({
                    fields: {
                      logo: '',
                      name: snapshot.val().name,
                    },
                  });
                  localStorage.setItem(localStorageSchoolKey, id);
                }
              })
              .catch(error => {
                console.error(error);
              });
          } catch (e) {
            console.error(e);
          }
        });
    } catch (e) {
      console.error(e);
    }
  };

  const getProgress = async user => {
    try {
      // Check for existing user in firebase and get progress - else setup a new user
      const dbRef = ref(getDatabase(app));

      await get(child(dbRef, `users/${user}`))
        .then(async snapshot => {
          if (snapshot.exists()) {
            // check that the user has step 1 & 2 progess (upgrade patch for existing users to the new progress setup)
            if (!snapshot.val().step1Progress) {
              // create step 1 and 2 progress and upload to Firebase
              const db = getDatabase();

              const curriculumData = {
                ...snapshot.val(),
                step1Progress: [false, false, false, false],
                step2Progress: [false, false, false, false, false],
              };

              await set(ref(db, 'users/' + user), curriculumData);
              setProgress(curriculumData);
            } else {
              setProgress(snapshot.val());
            }
          } else {
            // Run Firebase setup for user
            await firebaseUserSetup(user);
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const completeLesson = async (user, themeId, questionId, lessonId) => {
    // Complete lesson

    // Complete lesson in progress state

    const updatedProgress = {
      ...progress,
      [themeId]: {
        ...progress[themeId],
        questions: {
          ...progress[themeId].questions,
          [questionId]: {
            lessons: {
              ...(progress[themeId].questions[questionId] &&
                progress[themeId].questions[questionId].lessons),
              [lessonId]: {
                id: lessonId,
                complete: true,
              },
            },
          },
        },
      },
    };

    setProgress(updatedProgress);

    // Complete lesson in firebase
    const db = getDatabase();
    await set(
      ref(
        db,
        `users/${user}/${themeId}/questions/${questionId}/lessons/${lessonId}`
      ),
      {
        id: lessonId,
        complete: true,
      }
    );
  };

  const completeStepProgress = async (user, step, id) => {
    const updatedProgress = {
      ...progress,
    };
    updatedProgress[step][id] = true;

    setProgress(updatedProgress);

    // Complete step in firebase
    const db = getDatabase();
    await set(ref(db, `users/${user}/${step}/${id}`), true);
  };

  const initialiseSchool = async () => {
    const schoolId = isBrowser
      ? localStorage.getItem(localStorageSchoolKey)
      : null;

    // Get school data from Contentful if we have key set (Note: on sign out remove school local storage key)
    if (schoolId) {
      // Get school data from Contentful
      await getSchool(schoolId);
    }
  };

  const login = async emailAddress => {
    try {
      // Find school id from email address
      await client
        .getEntries({
          content_type: 'school',
          'fields.userEmail[match]': emailAddress,
        })
        .then(schools => {
          // Get school data and set local storage key for reuse
          const school = schools.items.find(
            school => school.fields.userEmail === emailAddress
          );
          if (school) {
            setSchool(school);
            localStorage.setItem(localStorageSchoolKey, school.sys.id);
          }
        })
        .catch(console.error);
    } catch (e) {
      console.error(e);
    }
  };

  const logout = () => {
    // Remove local storage key + any other clean up functions
    localStorage.removeItem(localStorageSchoolKey);
    setSchool(defaultValues.school);
  };

  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,

        school,

        progress,

        isOverlayActive,
        setOverlayActive,

        videoUrl,
        setVideoUrl,

        getProgress,
        completeStepProgress,
        completeLesson,

        userId,
        login,
        logout,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
